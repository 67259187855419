import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ContentTable = _resolveComponent("ContentTable")!

  return (_openBlock(), _createBlock(_component_ContentTable, {
    data: _ctx.courses,
    loading: _ctx.loading
  }, {
    "top-right-content": _withCtx(() => [
      _createVNode(_component_Button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({
        name: _ctx.EducationRouteNames.CONTENT_COURSES_CREATE_ABOUT
      }))),
        class: "p-button-rounded",
        label: "Crear Curso"
      })
    ]),
    _: 1
  }, 8, ["data", "loading"]))
}