
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { EducationRouteNames } from '@/router/route-names'
import { defineComponent, onMounted, ref } from 'vue'
import ContentTable from '../components/ContentTable.vue'
export default defineComponent({
  components: {
    ContentTable
  },
  setup () {
    const courses = ref<CoursePlan[]>([])
    const loading = ref<boolean>(false)
    onMounted(async () => {
      try {
        loading.value = true
        courses.value = await CoursePlanAPI.findAll()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })

    return {
      courses,
      loading,
      EducationRouteNames
    }
  }
})
