
import { IEducationPlan } from '@/models/academic-definitions/interfaces/EducationPlan'
import { FilterMatchMode } from 'primevue/api'
import { EducationRouteNames } from '@/router/route-names'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    data: {
      type: Array as PropType<IEducationPlan[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup () {
    const expandedRows = ref<IEducationPlan[]>([])
    return {
      window,
      expandedRows,
      EducationRouteNames,
      filters: ref({ global: { value: null, matchMode: FilterMatchMode.CONTAINS }, 'information.name': { value: null, matchMode: FilterMatchMode.CONTAINS } }),
      toggleExpand: (data: IEducationPlan) => {
        if (!expandedRows.value.includes(data)) {
          expandedRows.value = [...expandedRows.value, data]
        } else {
          expandedRows.value = expandedRows.value.filter(r => r !== data)
        }
      }
    }
  }
})
